import React, { useState, useContext, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import toast from 'react-hot-toast';
import PreloginLayout from '../../../layouts/prelogin/PreloginLayout';
import Header from '../../../components/table/Header';
import NavBar from '../../../components/nav-bar/NavBar';
import SiteDetails from './sections/site/SiteDetails';
import PreferenceDetails from './sections/preferences/PreferenceDetails';
import ShippingDetails from './sections/shipping/ShippingDetails';
import BillingDetails from './sections/billing/BillingDetails';
import PricingDetails from './sections/pricing/PricingDetails';
import AdditionalDetails from './sections/additional/AdditionalDetails';
import { Button } from '../../../components/buttons/Button/Button';
import styles from './AddProvider.module.scss';
import { LoginContext } from '../../../contexts/LoginContext/LoginContext';
import { authFetch } from '../../../utils/utils';
import { Toaster } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { LoginRoutes } from '../../../routers/DashRouter';
import useSkus from '../../../hooks/useSkus';
import useCountries from '../../../hooks/useCountries';
import SummaryModal from './components/SummaryModal';
import { isValidPhoneNumber } from 'react-phone-number-input';

const steps = [
  { title: "Site Details", component: SiteDetails },
  { title: "Preference Details", component: PreferenceDetails },
  { title: "Shipping Address", component: ShippingDetails },
  { title: "Billing Address", component: BillingDetails },
  { title: "Pricing Details", component: PricingDetails },
  { title: "Additional Details", component: AdditionalDetails },
];

const AddProvider: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [showStaffIdError, setShowStaffIdError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const { state: { loginToken } } = useContext(LoginContext);
  const methods = useForm({
    mode: 'onTouched',
    reValidateMode: 'onSubmit',
    defaultValues: {
      provider: {
        label: '',
        description: '',
        country: 'US',
        currency: 'USD',
        domain: '',
        videoLink: '',
      },
      shipping: {
        enabled: false,
        shippingAddress1: '',
        shippingName: '',
        shippingCity: '',
        shippingZip: '',
        shippingState: '',
        shippingCountry: 'US',
        shippingEmail: '',
        shippingPhone: '',
      },
      billing: {
        enabled: false,
        sameAsShipping: true,
        billingAddress1: '',
        billingName: '',
        billingCity: '',
        billingZip: '',
        billingState: '',
        billingCountry: 'US',
        billingEmail: '',
        billingPhone: '',
      },
      stfid: null,
      pricing: [],
      preferences: [],
    },
  });

  const { handleSubmit } = methods;

  const history = useHistory();

  const { skus } = useSkus();
  const { countries } = useCountries();

  const onSubmit = async (data: any) => {
    if (!data.stfid) {
      setShowStaffIdError(true);
      return;
    }
    
    if (!loginToken) {
      console.error("No login token available");
      toast.error("Authentication error");
      return;
    }

    setShowStaffIdError(false);
    setIsSubmitting(true);
    
    try {
      let stripeCustomerKey;
      
      // Create Stripe customer if billing is enabled
      if (data.billing?.enabled) {
        const billingData = data.billing.sameAsShipping ? {
          name: data.shipping.shippingName,
          email: data.shipping.shippingEmail,
          phone: data.shipping.shippingPhone,
          address: data.shipping.shippingAddress1,
          city: data.shipping.shippingCity,
          countryCode: data.shipping.shippingCountry,
          zipCode: data.shipping.shippingZip,
          stateCode: data.shipping.shippingState,
        } : {
          name: data.billing.billingName,
          email: data.billing.billingEmail,
          phone: data.billing.billingPhone,
          address: data.billing.billingAddress1,
          city: data.billing.billingCity,
          countryCode: data.billing.billingCountry,
          zipCode: data.billing.billingZip,
          stateCode: data.billing.billingState,
        };
        console.log('billingbody-->', JSON.stringify({ billing: billingData }));
        const stripeResponse = await authFetch(loginToken, '/api/create-stripe-customer-for-staff', {
          method: 'POST',
          body: JSON.stringify({ billing: billingData })
        });

        if (!stripeResponse.ok) {
          throw new Error('Failed to create Stripe customer');
        }

        const stripeData = await stripeResponse.json();
        stripeCustomerKey = stripeData.stripeCustomerKey;
      }

      // Clean up data and submit provider
      const cleanedPricing = data.pricing.map(({ dashAdminDefault, ...rest }: any) => rest);
      const cleanedShipping = data.shipping?.enabled ? 
        (({ enabled, ...rest }) => rest)(data.shipping) : 
        undefined;
      const cleanedBilling = data.billing?.enabled ? {
        ...(data.billing.sameAsShipping ? {
          billingName: data.shipping.shippingName,
          billingEmail: data.shipping.shippingEmail,
          billingPhone: data.shipping.shippingPhone,
          billingAddress1: data.shipping.shippingAddress1,
          billingCity: data.shipping.shippingCity,
          billingCountry: data.shipping.shippingCountry,
          billingZip: data.shipping.shippingZip,
          billingState: data.shipping.shippingState,
        } : (({ enabled, sameAsShipping, ...rest }) => rest)(data.billing)),
        stripeCustomerKey
      } : undefined;

      const formattedData = {
        provider: data.provider,
        shipping: cleanedShipping,
        billing: cleanedBilling,
        pricing: cleanedPricing,
        preferences: data.preferences.map((pref: any) => ({
          pp_id: pref.ppId,
          val: pref.val
        })),
        stfid: data.stfid
      };

      console.log('Submitting formatted data:', formattedData);

      const response = await authFetch(loginToken, '/api/provider', {
        method: 'POST',
        body: JSON.stringify(formattedData),
      });

      if (response.ok) {
        const result = await response.json();
        if (result.success) {
          // setTimeout(() => history.push(LoginRoutes.DASH_ADMIN.path), 1000);
          toast.success('Provider added successfully');
          history.push(LoginRoutes.DASH_ADMIN.path);
          // toast.success('Provider added successfully');
        } else {
          toast.error(`Failed to add provider: ${result.error}`);
        }
      } else {
        toast.error(`Failed to add provider: ${response.statusText}`);
      }
    } catch (error) {
      toast.error(`Error submitting provider: ${error instanceof Error ? error.message : 'Unknown error'}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const nextStep = (e: React.MouseEvent) => {
    e.preventDefault();
    console.log('=== Navigation: Next ===');
    console.log('Current Step:', currentStep);
    console.log('Form Data:', methods.getValues());
    
    methods.trigger().then((isValid) => {
      if (isValid) {
        // Additional check for shipping phone validation
        const formData = methods.getValues();
        if (currentStep === 2 && formData.shipping?.enabled) {
          const phone = formData.shipping.shippingPhone;
          if (!phone || !isValidPhoneNumber(phone)) {
            toast.error('Please enter a valid phone number');
            return;
          }
        }
        setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1));
      }
    });
  };

  const prevStep = (e: React.MouseEvent) => {
    e.preventDefault();
    console.log('=== Navigation: Previous ===');
    console.log('Current Step:', currentStep);
    console.log('Form Data:', methods.getValues());
    
    setCurrentStep((prev) => Math.max(prev - 1, 0));
  };

  const handleNavClick = (index: number) => {
    methods.trigger().then((isValid) => {
      if (isValid) {
        setCurrentStep(index);
      }
    });
  };

  const renderCurrentStep = () => {
    if (currentStep === steps.length - 1) {
      return (
        <>
          <AdditionalDetails 
            showError={showStaffIdError} 
            setShowStaffIdError={setShowStaffIdError} 
          />
          <SummaryModal
            isOpen={showSummaryModal}
            onClose={() => setShowSummaryModal(false)}
            onSubmit={handleSubmit(onSubmit)}
            isSubmitting={isSubmitting}
            skus={skus || []}
          />
        </>
      );
    }
    
    const CurrentStepComponent = steps[currentStep].component;
    return <CurrentStepComponent 
      skus={skus || []}
      countries={countries || []}
    />;
  };

  return (
    <PreloginLayout>
      <Header title="Add Provider" />
      <div className={styles.container}>
        <NavBar 
          items={steps.map((step, index) => ({ 
            title: step.title, 
            active: index === currentStep 
          }))} 
          onItemClick={handleNavClick}
        />
        <div className={styles.content}>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              {renderCurrentStep()}
              <div className={styles.navigation}>
                {currentStep > 0 && (
                  <Button variant="outline" onClick={prevStep}>
                    Back
                  </Button>
                )}
                {currentStep < steps.length - 1 && (
                  <Button onClick={nextStep}>
                    Next
                  </Button>
                )}
                {currentStep === steps.length - 1 && (
                  <Button 
                    onClick={(e) => {
                      e.preventDefault();
                      if (!methods.getValues().stfid) {
                        setShowStaffIdError(true);
                        return;
                      }
                      setShowStaffIdError(false);
                      setShowSummaryModal(true);
                    }}
                  >
                    Review Details
                  </Button>
                )}
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </PreloginLayout>
  );
};

export default AddProvider;