import { useForm, useFormContext as _useFormContext, FormProvider } from "react-hook-form";
import s from "./RequestOrder.module.scss";
import FormErrorMessageGeneric from "../../../../components/errors/FormErrorMessage/FormErrorMessageGeneric";
import ProviderSelect from "./inputs/ProviderSelect";
import StaffSelect from "./inputs/StaffSelect";
import SkuSelect from "./inputs/SkuSelect";
import QuantityInput from "./inputs/QuantityInput";
import { AppError, authFetch } from "../../../../utils/utils";
import { RequestOrderContext, RequestOrderContextProvider } from "./RequestOrderContext";
import PreloginLayout from "../../../../layouts/prelogin/PreloginLayout";
import { useContext, useEffect } from "react";
import IFTAInput from "../../../register/IFTAElements/IFTAInput/IFTAInput";
import useSubmitReducer from "../../../../hooks/useSubmitReducer";
import { LoginContext } from "../../../../contexts/LoginContext/LoginContext";
import { DateTime } from "luxon";
import { OrderedOnDateInput } from "./inputs/OrderedOnDateInput";
import { Price } from "../../../bulk-ordering/Price";
import { OrderCurrencyCode, currencies } from "../../../../contexts/OrderContext/OrderContext";
import OrderItemsBasket from "./OrderItemsBasket";
import { SkuFullPrice } from "../useOrderRequestPricingFunctions";
import _round from "lodash/round";

interface FormFieldValues {
  prid: number;
  stfid: number;
  skuId: number;
  quantity: number;
  orderedOn: string;
  currency: string;
  unitPriceBase: number;
  unitDiscount: number;
  unitTax: number;
  items: {
    skuId: number;
    quantity: number;
    unitPriceNet: number;
    unitDiscount: number;
    unitPriceGross: number;
    unitTax: number;
  }[];
}

export default function RequestOrderPrepaid() {
  const form = useForm<FormFieldValues>({
    defaultValues: {
      prid: undefined,
      stfid: undefined,
      skuId: undefined,
      quantity: 1,
      orderedOn: DateTime.now().toISODate(),
      unitPriceBase: undefined,
      unitDiscount: undefined,
      unitTax: undefined,
      items: [],
    },
  });

  return (
    <PreloginLayout>
      <div className={s.dashAdminAddOrder}>
        <FormProvider {...form}>
          <RequestOrderContextProvider isArrears={false}>
            <h1>Request Pre-Paid Order</h1>
            <Form />
          </RequestOrderContextProvider>
        </FormProvider>
      </div>
    </PreloginLayout>
  );
}

function Form() {
  const form = useFormContext();
  const [{ processing, complete, error }, dispatch] = useSubmitReducer(form);

  const { loginToken } = useContext(LoginContext).state;
  const { order, item } = useContext(RequestOrderContext).state;

  const onSubmit = (data: FormFieldValues) => {
    if (!loginToken) return;
    dispatch({ type: "PROCESSING" });
    requestPrePaidOrder(loginToken, data)
      .then(() => dispatch({ type: "COMPLETE" }))
      .catch((err) => dispatch({ type: "ERROR", payload: err }));
  };

  const canSubmit = !!(item && loginToken && !processing && !complete && order?.orderItems.length);

  const currency = item?.base.currency;
  useEffect(() => {
    if (!currency) return;
    form.setValue("currency", currency);
  }, [currency]);

  useEffect(() => {
    form.setValue(
      "items",
      order?.orderItems.map((item) => ({
        skuId: item.sku.skuId,
        quantity: item.quantity,
        unitPriceGross: item.unitPriceGross,
        unitDiscount: item.unitDiscount,
        unitPriceNet: item.unitPriceNet,
        unitTax: item.unitTax,
      })) ?? []
    );
  }, [order]);

  if (error) return <Err error={error} />;

  if (complete) return <Complete />;

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <div className={s.formInputs}>
        <ProviderSelect />
        <FormErrorMessage path="prid" />
        <StaffSelect />
        <FormErrorMessage path="stfid" />
        <OrderedOnDateInput />
        <FormErrorMessage path="orderedOn" />
        <ItemWidget />
      </div>
      <OrderItemsBasket processing={processing} canSubmit={canSubmit} />
    </form>
  );
}

function ItemWidget() {
  const form = useFormContext();
  const { dispatch } = useContext(RequestOrderContext);

  const price = useAdjustedPrice();

  const addItem = async () => {
    if (!(await form.trigger())) return;
    dispatch({ type: "ADD_ITEM", payload: price });
  };

  return (
    <div className={s.itemWidget}>
      <h4>Order Item</h4>
      <SkuSelect />
      <FormErrorMessage path="skuId" />
      <QuantityInput />
      <FormErrorMessage path="quantity" />
      <PriceInputs />
      <button className={s.btnPrimary} type="button" disabled={!price} onClick={addItem}>
        Add Item
      </button>
    </div>
  );
}

function useAdjustedPrice(): SkuFullPrice | undefined {
  const { watch } = useFormContext();
  const { unitPriceBase, unitDiscount, unitTax, currency, quantity } = watch();

  const { state } = useContext(RequestOrderContext);
  const item = state.item;
  if (!item) return undefined;

  const unitPriceGross = unitPriceBase - unitTax;
  const price = new Price({
    name: "",
    currency: currencies[currency as OrderCurrencyCode],
    unitPriceGross,
    unitDiscount,
    unitTax,
    quantity,
  });

  return {
    sku: item.sku,
    base: item.base,
    price,
  };
}

function PriceInputs() {
  const { register, getFieldState, setValue, getValues } = useFormContext();

  const {
    state: { item },
  } = useContext(RequestOrderContext);

  useEffect(() => {
    if (!item) return;
    if (getFieldState("unitPriceBase").isDirty) return;
    if (getFieldState("unitDiscount").isDirty) return;
    if (getFieldState("unitTax").isDirty) return;
    setValue("unitPriceBase", item.base.price);
    setValue("unitDiscount", item.price.unitDiscount);
    setValue("unitTax", item.price.unitTax);
  }, [item]);

  return (
    <>
      <IFTAInput
        id="unit-price-base"
        label="Unit Price (base, tax inclusive)"
        type="number"
        step="0.01"
        disabled={!item}
        reg={register("unitPriceBase", {
          required: "Base price is required",
          valueAsNumber: true,
          min: {
            value: 0,
            message: "Unit price cannot be negative",
          },
          validate: (basePrice) =>
            basePrice - getValues("unitTax") - getValues("unitDiscount") >= 0 ||
            "Tax and discount combined cannot be greater than base price",
        })}
      />
      <FormErrorMessage path="unitPriceBase" />
      <IFTAInput
        id="unit-discount"
        label="Unit Discount"
        type="number"
        step="0.01"
        disabled={!item}
        reg={register("unitDiscount", {
          required: "Unit discount is required",
          valueAsNumber: true,
          min: {
            value: 0,
            message: "Unit price cannot be negative",
          },
          validate: (unitDiscount) =>
            getValues("unitPriceBase") >= unitDiscount || "Discount cannot be greater than base price",
        })}
      />
      <FormErrorMessage path="unitDiscount" />
      <IFTAInput
        id="unit-tax"
        label="Unit Tax"
        type="number"
        step="0.01"
        disabled={!item}
        reg={register("unitTax", {
          required: "Unit  tax is required",
          valueAsNumber: true,
          min: {
            value: 0,
            message: "Unit tax cannot be negative",
          },
          validate: (unitTax) => getValues("unitPriceBase") >= unitTax || "Unit tax cannot be greater than base price",
        })}
      />
      <FormErrorMessage path="unitTax" />
    </>
  );
}

function Complete() {
  return (
    <div className={s.complete}>
      <h2>Success</h2>
      <p>Order Requested</p>
    </div>
  );
}

function Err({ error }: { error: AppError }) {
  return (
    <div className={s.error}>
      <h1>Error {error.code}</h1>
      <p>{error.message}</p>
    </div>
  );
}

async function requestPrePaidOrder(loginToken: string, data: FormFieldValues) {
  const res = await authFetch(loginToken, "/api/order-request/pre-paid", {
    method: "POST",
    body: JSON.stringify(body(data)),
  });
  const { error } = await res.json();
  if (error !== undefined) throw new AppError(res.status, error);
  return;
}

function createPrice(data: FormFieldValues) {
  const { unitPriceBase, unitDiscount, unitTax, currency, quantity } = data;
  const unitPriceGross = unitPriceBase - unitTax;
  return new Price({
    name: "",
    currency: currencies[currency as OrderCurrencyCode],
    unitPriceGross,
    unitDiscount,
    unitTax,
    quantity,
  });
}

function body(data: FormFieldValues): PostBody {
  return {
    prid: data.prid,
    stfid: data.stfid,
    currency: data.currency,
    orderedOn: data.orderedOn,
    items: data.items,
  };
}

interface PostBody {
  prid: number;
  stfid: number | undefined;
  currency: string;
  orderedOn: string;
  items: {
    skuId: number;
    quantity: number;
    unitPriceNet: number;
    unitDiscount: number;
    unitPriceGross: number;
    unitTax: number;
  }[];
}

const FormErrorMessage = FormErrorMessageGeneric<FormFieldValues>;

const useFormContext = _useFormContext<FormFieldValues>;
