import React, { useState, useEffect, useRef, ReactNode } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import styles from './Dropdown.module.scss';

interface DropdownProps {
  children: ReactNode;
  menu: {
    items: string[];
    onSelect?: (item: string) => void;
    title?: string;
  };
  size?: 'default' | 'small';
  position?: 'default' | 'bottom-right';
}

export const Dropdown: React.FC<DropdownProps> = ({ children, menu, size = 'default', position = 'default' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleSelect = (item: string) => {
    setIsOpen(false);
    if (menu.onSelect) {
      menu.onSelect(item);
    }
  };

  return (
    <div ref={dropdownRef} className={styles.dropdown}>
      <div onClick={handleToggle}>{children}</div>
      {isOpen && (
        <div 
          className={`${styles.dropdown__menu} ${styles[`dropdown__menu--${size}`]} ${styles[`dropdown__menu--${position}`]}`} 
          onClick={(e) => e.stopPropagation()}
        >
          {menu.title && (
            <div className={styles.dropdown__title}>{menu.title}</div>
          )}
          {menu.items.map((item, index) => (
            <div
              key={index}
              className={`${styles.dropdown__item} ${styles[`dropdown__item--${size}`]}`}
              onClick={() => handleSelect(item)}
            >
              {item}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};