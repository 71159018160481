import React, { useContext, useState, useEffect } from "react";
import Modal from "../../../../../components/modal/Modal";
import { Form } from "../../../../../components/Form/Form";
import Input from "../../../../../components/inputs/Input/Input";
import styles from "./EditBillingDetailsModal.module.scss";
import { BillingDetails } from "../BillingTable";
import { LoginContext } from "../../../../../contexts/LoginContext/LoginContext";
import { authFetch } from "../../../../../utils/utils";
import { Dropdown } from "../../../../../components/dropdown/Dropdown";
import useCountries from "../../../../../hooks/useCountries";
import { IoIosArrowDown } from 'react-icons/io';
import toast from "react-hot-toast";

const EditBillingDetailsModal: React.FC<EditBillingDetailsModalProps> = ({
  isOpen,
  onClose,
  billingDetails,
  onUpdate,
}) => {
  const { state: { loginToken } } = useContext(LoginContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { countries } = useCountries();
  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);

  useEffect(() => {
    if (billingDetails && countries) {
      const matchingCountry = countries.find(c => c.country === billingDetails.billingCountry);
      if (matchingCountry) {
        setSelectedCountry(matchingCountry.label);
      }
    }
  }, [billingDetails, countries]);

  if (!billingDetails) return null;

  const handleFormCancel = () => {
    onClose();
  };

  const handleCountrySelect = (label: string) => {
    setSelectedCountry(label);
  };

  const handleSubmit = async (data: Partial<BillingDetails>) => {
    if (!loginToken) {
      console.error("No login token available");
      toast.error("Authentication error");
      return;
    }
    setIsSubmitting(true);
    try {
      const selectedCountryData = countries?.find(c => c.label === selectedCountry);
      const submissionData = {
        ...data,
        billingCountry: selectedCountryData?.country,
      };

      const response = await authFetch(loginToken, `/api/update-billing/${billingDetails.blid}`, {
        method: "PUT",
        body: JSON.stringify(submissionData),
      });

      if (response.ok) {
        const updatedBilling = await response.json();
        const fullUpdatedBilling: BillingDetails = {
          ...billingDetails,
          ...submissionData,
          ...updatedBilling.billing_info
        };
        toast.success('Billing details updated successfully');
        onUpdate(fullUpdatedBilling);
        onClose();
      } else {
        toast.error(`Failed to update billing details: ${response.statusText}`);
      }
    } catch (error) {
      toast.error(`Error updating billing details: ${error instanceof Error ? error.message : 'Unknown error'}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      title="Edit Billing Details"
      open={isOpen}
      onCancel={onClose}
    >
      <Form<Partial<BillingDetails>>
        onSubmit={handleSubmit}
        defaultValues={billingDetails}
        footer={{
          onCancel: handleFormCancel,
          submitText: "Update Billing Details",
          loadingText: "Updating",
          isSubmitting,
        }}
      >
        <div className={styles.inputGroup}>
          <Input 
            name="blid" 
            label="Billing ID" 
            disabled
          />
          <Input 
            name="billingName" 
            label="Billing Name" 
            placeholder="Recipient Name (e.g. 'John Doe')"
            rules={{ required: 'Billing name is required' }} 
            required
          />
        </div>
        <div className={styles.inputGroup}>
          <Input 
            name="billingEmail" 
            label="Email" 
            type="email"
            placeholder="Email (e.g. 'johndoe@example.com')"
            rules={{ 
              required: 'Email is required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address"
              }
            }} 
            required
          />
          <Input 
            name="billingPhone" 
            label="Phone Number" 
            placeholder="Phone Number (e.g. '+1 489 234 5678')"
            rules={{ required: 'Phone number is required' }} 
            required
          />
        </div>
        <Input 
          name="billingAddress1" 
          label="Address" 
          placeholder="Address (e.g. '123 Main Street')"
          rules={{ required: 'Address is required' }} 
          required
        />
        <div className={styles.inputGroup}>
          <Input 
            name="billingCity" 
            label="City" 
            placeholder="City (e.g. 'San Francisco')"
            rules={{ required: 'City is required' }} 
            required
          />
          <Input 
            name="billingState" 
            label="State / Province"
            placeholder={selectedCountry === "United States of America" ? "State (e.g. 'CA')" : "Province (e.g. 'Ontario')"}
            rules={{ required: selectedCountry === "United States of America" ? 'State is required' : undefined }}
            required={selectedCountry === "United States of America"}
          />
        </div>
        <div className={styles.inputGroup}>
          <Input 
            name="billingZip" 
            label="Postal Code" 
            placeholder="Postal Code (e.g. '95132')"
            rules={{ required: 'Postal code is required' }} 
            required
          />
          <div className={styles.dropdownWrapper}>
            <label htmlFor="billingCountry" className={styles.label}>Country *</label>
            <Dropdown
              menu={{
                items: countries?.map(country => country.label) || [],
                onSelect: handleCountrySelect,
              }}
              size="small"
            >
              <div className={styles.preferenceValue}>
                {selectedCountry || 'Select Country'}
                <span className={styles.iconWrapper}>
                  <IoIosArrowDown />
                </span>
              </div>
            </Dropdown>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default EditBillingDetailsModal;

interface EditBillingDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  billingDetails: BillingDetails | null;
  onUpdate: (data: BillingDetails) => void;
}
