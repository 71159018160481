import React, { useContext, useState, useMemo, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import Modal from "../../../../../components/modal/Modal";
import { Form } from "../../../../../components/Form/Form";
import Input from "../../../../../components/inputs/Input/Input";
import { Dropdown } from "../../../../../components/dropdown/Dropdown";
import styles from "./AddPricingModal.module.scss";
import { LoginContext } from "../../../../../contexts/LoginContext/LoginContext";
import { authFetch } from "../../../../../utils/utils";
import { Sku } from "../../../../../hooks/useSkus";
import { IoIosArrowDown } from 'react-icons/io';
import { PricingDetails } from "../PricingTable";
import toast from "react-hot-toast";

// Form content component to access form context
const FormContent: React.FC<{
  selectedSku: string | null;
  handleSkuSelect: (item: string) => void;
  skuOptions: string[];
  selectedCurrency: string | null;
  handleCurrencySelect: (item: string) => void;
  currencyOptions: string[];
  validateStartDate: (value: string) => true | string;
  validateEndDate: (value: string) => true | string;
  submitError: string | null;
}> = ({
  selectedSku,
  handleSkuSelect,
  skuOptions,
  selectedCurrency,
  handleCurrencySelect,
  currencyOptions,
  validateStartDate,
  validateEndDate,
  submitError,
}) => {
  const { clearErrors } = useFormContext();
  
  const handleSkuSelectWithReset = (item: string) => {
    handleSkuSelect(item);
    clearErrors("started_on");
  };

  return (
    <>
      <div className={styles.dropdownWrapper}>
        <label htmlFor="sku_id">SKU *</label>
        <Dropdown
          menu={{
            items: skuOptions.filter(sku => sku !== selectedSku),
            onSelect: handleSkuSelectWithReset,
          }}
          size="small"
        >
          <div className={styles.preferenceValue}>
            {selectedSku || 'Select SKU'}
            <span className={styles.iconWrapper}>
              <IoIosArrowDown />
            </span>
          </div>
        </Dropdown>
      </div>
      <Input
        name="description" 
        label="Product Name" 
        placeholder="Product Name (e.g. 'MedAIRE 2 - US (Westminster Medical Clinic)')"
        rules={{ required: 'Product name is required' }} 
        required
      />
      <div className={styles.inputGroup}>
        <Input 
          name="started_on" 
          label="Started On" 
          type="datetime-local"
          rules={{ 
            required: 'Start date is required',
            validate: validateStartDate
          }}
          required
        />
        <Input 
          name="ended_on" 
          label="Ended On" 
          type="datetime-local"
          rules={{ 
            validate: validateEndDate
          }} 
        />
      </div>
      <div className={styles.inputGroup}>
        <div className={styles.dropdownWrapper}>
          <label htmlFor="currency">Currency *</label>
          <Dropdown
            menu={{
              items: currencyOptions.filter(currency => currency !== selectedCurrency),
              onSelect: handleCurrencySelect,
            }}
            size="small"
          >
            <div className={styles.preferenceValue}>
              {selectedCurrency || 'Select Currency'}
              <span className={styles.iconWrapper}>
                <IoIosArrowDown />
              </span>
            </div>
          </Dropdown>
        </div>
        <Input 
          name="price" 
          label="Price" 
          type="number"
          step="0.01"
          placeholder="120"
          rules={{ 
            required: 'Price is required',
            validate: (value) => value > 0 || 'Price must be positive'
          }} 
          required
        />
      </div>
      <Input 
        name="tax_amount" 
        label="Tax (%)" 
        type="number"
        step="0.01"
        placeholder="25"
        rules={{ 
          required: 'Tax is required',
          validate: (value) => (value >= 0 && value <= 100) || 'Tax must be between 0% and 100%'
        }} 
        required
      />
      {submitError && <div className={styles.errorMessage}>{submitError}</div>}
    </>
  );
};

interface AddPricingModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAdd: (data: PricingDetails) => void;
  providerId: string;
  currencyOptions: string[];
  defaultCurrency: string | null;
  skus: Sku[];
  providerPricing: PricingDetails[];
}

const AddPricingModal: React.FC<AddPricingModalProps> = ({
  isOpen,
  onClose,
  onAdd,
  providerId,
  currencyOptions,
  defaultCurrency,
  skus,
  providerPricing,
}) => {
  const { state: { loginToken } } = useContext(LoginContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [selectedSku, setSelectedSku] = useState<string | null>(null);
  const [selectedCurrency, setSelectedCurrency] = useState<string | null>(null);

  useEffect(() => {
    if (defaultCurrency) {
      setSelectedCurrency(defaultCurrency);
    }
  }, [defaultCurrency]);

  const skuOptions = useMemo(() => {
    if (!skus) return [];
    return skus.map(sku => `${sku.skuId} - ${sku.shortLabel}`);
  }, [skus]);

  const handleSkuSelect = (item: string) => {
    setSelectedSku(item);
  };

  const formatDateForDisplay = (dateString: string | undefined) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  const validateStartDate = (value: string) => {
    if (!value) return 'Start date is required';
    const selectedSkuId = selectedSku ? parseInt(selectedSku.split(' - ')[0], 10) : null;
    if (!selectedSkuId) return true;

    const overlappingPricing = providerPricing.find(pricing => 
      pricing.sku_id === selectedSkuId && 
      new Date(value) < new Date(pricing.ended_on)
    );

    if (overlappingPricing) {
      return `Pricing with SKU ID ${selectedSkuId} already exists. Start date must be after the end date of pricing ID ${overlappingPricing.pricing_id} (${formatDateForDisplay(overlappingPricing.ended_on)})`;
    }

    return true;
  };

  const validateEndDate = (value: string) => {
    const startDate = (document.querySelector('input[name="started_on"]') as HTMLInputElement)?.value;
    return !value || !startDate || new Date(value) > new Date(startDate) || 'End date must be after start date';
  };

  const handleCurrencySelect = (item: string) => {
    setSelectedCurrency(item);
  };

  const handleSubmit = async (data: Partial<PricingDetails>) => {
    setSubmitError(null);
    if (!loginToken) {
      console.error("No login token available");
      toast.error("Authentication error");
      return;
    }
    setIsSubmitting(true);
    try {
      const [skuId, skuLabel] = selectedSku ? selectedSku.split(' - ') : [undefined, undefined];
      const submissionData = {
        ...data,
        sku_id: skuId ? parseInt(skuId, 10) : undefined,
        prid: parseInt(providerId, 10),
        currency: selectedCurrency,
        tax_amount: (data.tax_amount || 0) / 100,
      };
      const response = await authFetch(loginToken, `/api/insert-pricing/${providerId}`, {
        method: "POST",
        body: JSON.stringify(submissionData),
      });

      if (response.ok) {
        const result = await response.json();
        const newPricing: PricingDetails = {
          ...data,
          pricing_id: result.pricingid,
          prid: parseInt(providerId, 10),
          sku_id: skuId ? parseInt(skuId, 10) : 0,
          sku: skuLabel || '',
          created_on: new Date().toISOString(),
        } as PricingDetails;
        toast.success('Pricing added successfully');
        onAdd(newPricing);
        onClose();
      } else {
        const errorData = await response.json();
        toast.error(errorData.message || "Failed to add pricing");
      }
    } catch (error) {
      toast.error(`Error adding pricing: ${error instanceof Error ? error.message : 'Unknown error'}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      title="Add Pricing"
      open={isOpen}
      onCancel={onClose}
    >
      <Form<Partial<PricingDetails>>
        onSubmit={handleSubmit}
        footer={{
          onCancel: onClose,
          submitText: "Add Pricing",
          loadingText: "Adding",
          isSubmitting,
        }}
      >
        <FormContent
          selectedSku={selectedSku}
          handleSkuSelect={handleSkuSelect}
          skuOptions={skuOptions}
          selectedCurrency={selectedCurrency}
          handleCurrencySelect={handleCurrencySelect}
          currencyOptions={currencyOptions}
          validateStartDate={validateStartDate}
          validateEndDate={validateEndDate}
          submitError={submitError}
        />
      </Form>
    </Modal>
  );
};

export default AddPricingModal;


interface AddPricingModalProps {
    isOpen: boolean;
    onClose: () => void;
    onAdd: (data: PricingDetails) => void;
    providerId: string;
    currencyOptions: string[];
    defaultCurrency: string | null;
    skus: Sku[];
    providerPricing: PricingDetails[];
  }
