import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import PreloginLayout from '../../../../layouts/prelogin/PreloginLayout';
import Table from '../../../../components/table/Table';
import { TableButton } from '../../../../components/table/Table';
import Header from '../../../../components/table/Header';
import EditBillingDetailsModal from './billingModals/EditBillingDetailsModal';
import AddBillingModal from './billingModals/AddBillingModal';

const BillingTable = () => {
  const { providerId }: UrlParams = useParams();
  const location = useLocation<{ label: string }>();
  const [providerBilling, setProviderBilling] = useState<BillingDetails[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchProviderBilling = async () => {
    try {
      const response = await fetch(`/api/get-provider-billing-dashboard/${providerId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch provider billing data');
      }
      const data = await response.json();
      setProviderBilling(data.providerBilling);
    } catch (err) {
      setError('Error fetching provider billing data');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProviderBilling();
  }, [providerId]);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedBilling, setSelectedBilling] = useState<BillingDetails | null>(null);

  const handleEditClick = (billing: BillingDetails) => {
    setSelectedBilling(billing);
    setIsEditModalOpen(true);
  };

  const handleUpdateBilling = (updatedData: BillingDetails) => {
    setProviderBilling(prevBilling => 
      prevBilling.map(billing => 
        billing.blid === updatedData.blid ? updatedData : billing
      )
    );
    setIsEditModalOpen(false);
  };

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const handleAddClick = () => {
    setIsAddModalOpen(true);
  };

  const handleAddBilling = (newBilling: BillingDetails) => {
    fetchProviderBilling();
    setIsAddModalOpen(false);
  };

  const columns: Column[] = [
    { 
      key: 'createdOn', 
      header: 'Created On', 
      isDate: true,
      render: (value) => value ? new Date(value).toLocaleDateString() : 'N/A'
    },
    { key: 'blid', header: 'ID' },
    { key: 'billingName', header: 'Name' },
    { key: 'billingEmail', header: 'Email' },
    { key: 'billingPhone', header: 'Phone Number' },
    { key: 'billingAddress1', header: 'Address' },
    { key: 'billingCity', header: 'City' },
    { key: 'billingState', header: 'State' },
    { key: 'billingZip', header: 'Zip Code' },
    { key: 'billingCountry', header: 'Country'},
    { 
      key: 'editBillingDetails', 
      header: 'Edit Billing Details', 
      render: (_, rowData: BillingDetails) => (
        <TableButton label="Edit Details" onClick={() => handleEditClick(rowData)} />
      )
    },
  ];
 
  if (isLoading) {
    return <PreloginLayout><div>Loading...</div></PreloginLayout>;
  }

  if (error) {
    return <PreloginLayout><div>Error: {error}</div></PreloginLayout>;
  }

  return (
    <PreloginLayout>
      <Header 
        title={`Billing Addresses of ${location.state?.label}`}
        actionButton={{
          label: "Add Billing",
          onClick: handleAddClick
        }}
      />
      <Table 
        columns={columns} 
        data={providerBilling}
        searchableFields={['blid', 'billingName', 'billingEmail', 'billingPhone', 'billingCity', 'billingCountry']}
        searchPlaceholder="Search by ID, name, email, phone, city, or country..."
      />
      <EditBillingDetailsModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        billingDetails={selectedBilling}
        onUpdate={handleUpdateBilling}
      />
      <AddBillingModal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        providerId={providerId}
        onAdd={handleAddBilling}
      />
    </PreloginLayout>
  );
};

export default BillingTable;

interface UrlParams {
  providerId: string;
}

export interface BillingDetails {
  prid: number;
  blid: number;
  createdOn: string;
  billingName: string;
  billingEmail: string;
  billingPhone: string;
  billingAddress1: string;
  billingCity: string;
  billingZip: string;
  billingState: string | null;
  billingCountry: string;
}

interface Column {
  key: string;
  header: string;
  isDate?: boolean;
  render?: (value: any, rowData: BillingDetails) => React.ReactNode;
}
