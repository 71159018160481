import React, { useState, useMemo } from 'react';
import styles from './Table.module.scss';
import { FiArrowLeft, FiArrowRight, FiSearch, FiMoreHorizontal } from "react-icons/fi";
import { Button } from '../buttons/Button/Button';
import { Dropdown } from '../dropdown/Dropdown';

export const TableButton: React.FC<TableButtonProps> = ({ label, onClick, icon }) => (
  <button className={styles.button} onClick={onClick}>
    {icon === 'prev' && <FiArrowLeft />}
    {label}
    {icon === 'next' && <FiArrowRight />}
  </button>
);

interface TableProps {
  columns: ColumnDefinition[];
  data: any[];
  searchableFields?: string[];
  searchPlaceholder?: string;
  defaultSort?: {
    key: string;
    direction: 'asc' | 'desc';
  };
  actions?: {
    items: string[];
    onSelect: (action: string, rowData: any) => void;
  };
}

interface ColumnDefinition {
  key: string;
  header: string;
  isDate?: boolean;
  isId?: boolean;
  render?: (value: any, rowData: any) => React.ReactNode;
}

const Table: React.FC<TableProps> = ({ 
  columns: initialColumns, 
  data, 
  searchableFields, 
  searchPlaceholder = "Search...",
  defaultSort,
  actions
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');

  const filteredData = useMemo(() => {
    if (!searchTerm || !searchableFields) return data;

    return data.filter(item =>
      searchableFields.some(field =>
        String(item[field]).toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [data, searchTerm, searchableFields]);

  const sortedAndFilteredData = useMemo(() => {
    let processedData = [...filteredData];
    
    if (defaultSort) {
      processedData.sort((a, b) => {
        const aValue = a[defaultSort.key];
        const bValue = b[defaultSort.key];
        
        if (defaultSort.direction === 'desc') {
          return bValue > aValue ? 1 : -1;
        }
        return aValue > bValue ? 1 : -1;
      });
    }

    return processedData;
  }, [filteredData, defaultSort]);

  const rowsPerPage = sortedAndFilteredData.length <= 20 ? sortedAndFilteredData.length : 8;

  const columns = useMemo(() => {
    if (!actions) return initialColumns;

    const actionsColumn: ColumnDefinition = {
      key: 'actions',
      header: '',
      render: (_, rowData) => (
        <Dropdown
          size="small"
          position="bottom-right"
          menu={{
            title: 'Actions',
            items: actions.items,
            onSelect: (action) => actions.onSelect(action, rowData)
          }}
        >
          <Button
            variant="ghost"
            size="icon"
            icon={<FiMoreHorizontal />}
          />
        </Dropdown>
      )
    };

    return [...initialColumns, actionsColumn];
  }, [initialColumns, actions]);

  const totalPages = Math.ceil(sortedAndFilteredData.length / rowsPerPage);

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString)
    return date.toLocaleDateString('en-US')
  };

  const paginatedData = sortedAndFilteredData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <button
            key={i}
            onClick={() => setCurrentPage(i)}
            className={`${styles.pageNumber} ${currentPage === i ? styles.active : ''}`}
          >
            {i}
          </button>
        );
      }
    } else {
      const leftBound = Math.max(1, currentPage - 2);
      const rightBound = Math.min(totalPages, leftBound + 4);

      if (leftBound > 1) {
        pageNumbers.push(
          <button key={1} onClick={() => setCurrentPage(1)} className={styles.pageNumber}>
            1
          </button>
        );
        if (leftBound > 2) {
          pageNumbers.push(<span key="ellipsis1">...</span>);
        }
      }

      for (let i = leftBound; i <= rightBound; i++) {
        pageNumbers.push(
          <button
            key={i}
            onClick={() => setCurrentPage(i)}
            className={`${styles.pageNumber} ${currentPage === i ? styles.active : ''}`}
          >
            {i}
          </button>
        );
      }

      if (rightBound < totalPages) {
        if (rightBound < totalPages - 1) {
          pageNumbers.push(<span key="ellipsis2">...</span>);
        }
        pageNumbers.push(
          <button
            key={totalPages}
            onClick={() => setCurrentPage(totalPages)}
            className={styles.pageNumber}
          >
            {totalPages}
          </button>
        );
      }
    }

    return pageNumbers;
  };

  return (
    <div>
      {searchableFields && (
        <div className={styles.searchBar}>
          <div className={styles.inputWrapper}>
            <span className={styles.iconWrapper}><FiSearch /></span>
            <input
              type="text"
              placeholder={searchPlaceholder}
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setCurrentPage(1);
              }}
            />
          </div>
        </div>
      )}
      <table className={styles.table}>
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column.key}>{column.header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {paginatedData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columns.map((column) => (
                <td key={column.key} className={column.isDate ? styles.dateColumn : column.isId ? styles.idColumn : ''}>
                  {column.render 
                    ? column.render(row[column.key], row)
                    : column.isDate
                      ? formatDate(row[column.key])
                      : row[column.key]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {totalPages > 1 && sortedAndFilteredData.length > 20 && (
        <div className={styles.tableInfo}>
          Showing {((currentPage - 1) * rowsPerPage) + 1} to {Math.min(currentPage * rowsPerPage, sortedAndFilteredData.length)} of {sortedAndFilteredData.length} entries
        </div>
      )}
      {totalPages > 1 && sortedAndFilteredData.length > 20 && (
        <div className={styles.pagination}>
          <TableButton
            label="Previous"
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            icon="prev"
            disabled={currentPage === 1}
          />
          <div className={styles.pageNumbers}>{renderPageNumbers()}</div>
          <TableButton
            label="Next"
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
            icon="next"
            disabled={currentPage === totalPages}
          />
        </div>
      )}
    </div>
  );
};

export default Table;

interface TableButtonProps {
  label: string;
  onClick?: () => void;
  icon?: 'prev' | 'next';
  disabled?: boolean;
}