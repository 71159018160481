import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import Input from '../../../../../components/inputs/Input/Input';
import { Dropdown } from '../../../../../components/dropdown/Dropdown';
import useCountries from '../../../../../hooks/useCountries';
import { IoIosArrowDown } from 'react-icons/io';
import Switch from '../../../../../components/switch/Switch';
import styles from './BillingDetails.module.scss';
import PhoneInput from 'react-phone-number-input';
import { CountryCode } from 'libphonenumber-js';
import { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import phoneStyles from '../../../../../components/inputs/PhoneInput/PhoneInput.module.scss';

interface BillingDetailsProps {
  variant?: 'default' | 'summary';
}

const BillingDetails: React.FC<BillingDetailsProps> = ({ variant = 'default' }) => {
  const { control, setValue, watch, clearErrors } = useFormContext();
  const { countries } = useCountries();
  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [phoneError, setPhoneError] = useState<string>('');
  
  const isEnabled = watch('billing.enabled');
  const sameAsShipping = watch('billing.sameAsShipping');
  const shippingEnabled = watch('shipping.enabled');
  const shippingData = watch('shipping');
  const providerCountry = watch('provider.country');
  const billingData = watch('billing');

  useEffect(() => {
    if (isEnabled) {
      const currentPhone = billingData.billingPhone;
      if (currentPhone) {
        setPhoneNumber(currentPhone);
      }
    }
  }, [isEnabled]);

  const handlePhoneChange = (value: string) => {
    setPhoneNumber(value || '');
    
    if (!value) {
      setPhoneError(isEnabled ? 'Phone number is required' : '');
      setValue('billing.billingPhone', '', { 
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true
      });
      return;
    }

    if (!isValidPhoneNumber(value)) {
      setPhoneError('Invalid phone number');
      setValue('billing.billingPhone', value, { 
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true
      });
    } else {
      setPhoneError('');
      setValue('billing.billingPhone', value, { 
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true
      });
    }
  };

  useEffect(() => {
    if (countries && isEnabled) {
      const billingCountry = watch('billing.billingCountry');
      if (billingCountry) {
        const matchingCountry = countries.find(c => c.country === billingCountry);
        if (matchingCountry) {
          setSelectedCountry(matchingCountry.label);
        }
      } else if (providerCountry) {
        const matchingCountry = countries.find(c => c.country === providerCountry);
        if (matchingCountry) {
          setSelectedCountry(matchingCountry.label);
          setValue('billing.billingCountry', matchingCountry.country, { shouldValidate: true });
        }
      }
    } else if (!isEnabled) {
      setSelectedCountry(null);
    }
  }, [providerCountry, countries, isEnabled]);

  useEffect(() => {
    if (isEnabled) {
      if (sameAsShipping && shippingEnabled) {
        setValue('billing.billingName', shippingData.shippingName);
        setValue('billing.billingEmail', shippingData.shippingEmail);
        setValue('billing.billingPhone', shippingData.shippingPhone);
        setValue('billing.billingAddress1', shippingData.shippingAddress1);
        setValue('billing.billingCity', shippingData.shippingCity);
        setValue('billing.billingState', shippingData.shippingState);
        setValue('billing.billingZip', shippingData.shippingZip);
        setValue('billing.billingCountry', shippingData.shippingCountry);
        setPhoneNumber(shippingData.shippingPhone);
        setSelectedCountry(countries?.find(c => c.country === shippingData.shippingCountry)?.label || null);
      }
    }
  }, [isEnabled, sameAsShipping, shippingData, shippingEnabled]);

  useEffect(() => {
    if (isEnabled && !sameAsShipping) {
      const currentBilling = watch('billing');
      
      if (!currentBilling.billingName && !currentBilling.billingEmail) {
        ['billingName', 'billingEmail', 'billingPhone', 'billingAddress1',
         'billingCity', 'billingState', 'billingZip'].forEach(field => {
          setValue(`billing.${field}`, '', { shouldValidate: false });
        });
        setPhoneNumber('');
        setPhoneError('');
        
        if (providerCountry) {
          const matchingCountry = countries?.find(c => c.country === providerCountry);
          if (matchingCountry) {
            setValue('billing.billingCountry', matchingCountry.country, { shouldValidate: true });
            setSelectedCountry(matchingCountry.label);
          }
        }
      }
    }
  }, [sameAsShipping]);

  useEffect(() => {
    if (!isEnabled) {
      clearErrors('billing');
      setValue('billing', { enabled: false, sameAsShipping: true });
      setPhoneNumber('');
      setPhoneError('');
    }
  }, [isEnabled]);

  const handleBillingToggle = (newChecked: boolean) => {
    setValue('billing.enabled', newChecked);
    if (newChecked && shippingEnabled) {
      setValue('billing.sameAsShipping', true);
    }
  };

  const handleCountrySelect = (label: string) => {
    setSelectedCountry(label);
    const selectedCountryData = countries?.find(c => c.label === label);
    if (selectedCountryData) {
      setValue('billing.billingCountry', selectedCountryData.country, { shouldValidate: true });
      setPhoneNumber('');
      setPhoneError('');
      setValue('billing.billingPhone', '', { shouldValidate: false });
    }
  };

  const getCountryCode = (countryName: string | null): CountryCode => {
    if (!countryName) return 'US';
    const country = countries?.find(c => c.label === countryName);
    return (country?.country || 'US') as CountryCode;
  };

  const getValidationRules = (field: string) => {
    if (!isEnabled) return {};
    
    const rules: { [key: string]: any } = {
      billingName: { required: 'Billing name is required' },
      billingEmail: { 
        required: 'Email is required',
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          message: "Invalid email address"
        }
      },
      billingPhone: {
        required: 'Phone number is required',
        validate: (value: string) => {
          if (!value) return 'Phone number is required';
          return isValidPhoneNumber(value) || 'Invalid phone number';
        }
      },
      billingAddress1: { required: 'Address is required' },
      billingCity: { required: 'City is required' },
      billingZip: { required: 'Postal/Zip code is required' }
    };

    return rules[field] || {};
  };

  const containerClass = variant === 'summary' ? styles.summaryContainer : styles.container;
  const headerClass = variant === 'summary' ? styles.summaryHeader : styles.header;
  const contentClass = variant === 'summary' ? styles.summaryContent : styles.content;

  const phoneInputElement = (
    <div className={phoneStyles.phoneInputWrapper}>
      <label className={phoneStyles.label}>
        Phone Number {isEnabled && !sameAsShipping && '*'}
      </label>
      <PhoneInput
        international
        countryCallingCodeEditable={false}
        country={getCountryCode(selectedCountry)}
        value={phoneNumber}
        onChange={handlePhoneChange}
        required={isEnabled && !sameAsShipping}
        defaultCountry={getCountryCode(selectedCountry)}
        disabled={!isEnabled || !selectedCountry || sameAsShipping}
        countrySelectProps={{ disabled: true }}
        error={phoneError ? true : false}
      />
      {phoneError && isEnabled && !sameAsShipping && (
        <span className={phoneStyles.errorMessage}>{phoneError}</span>
      )}
    </div>
  );

  return (
    <div className={containerClass}>
      <div className={headerClass}>
        {variant === 'summary' ? (
          <h3>Billing Details</h3>
        ) : (
          <h2>Billing Details</h2>
        )}
        <Switch 
          checked={isEnabled}
          onChange={handleBillingToggle}
        />
      </div>
      
      {!isEnabled ? (
        <div className={styles.disabledMessage}>
          <div className={styles.messageCard}>
            <p>Enable billing using the toggle above if you need to add billing details for this provider.</p>
          </div>
        </div>
      ) : (
        <div className={contentClass}>
          <div className={styles.checkboxWrapper}>
            <label className={styles.checkbox}>
              <input
                type="checkbox"
                checked={sameAsShipping}
                onChange={(e) => setValue('billing.sameAsShipping', e.target.checked)}
                disabled={!shippingEnabled}
              />
              <span className={styles.checkboxLabel}>
                Same as shipping address
                {!shippingEnabled && (
                  <span className={styles.disabledText}> (Enable shipping first)</span>
                )}
              </span>
            </label>
          </div>

          {(!sameAsShipping || !shippingEnabled) && (
            variant === 'summary' ? (
              <>
                <div className={styles.summaryRow}>
                  <div className={styles.summaryHalf}>
                    <Input 
                      name="billing.billingName" 
                      label="Billing Name"
                      placeholder='Name (e.g. "John Doe")'
                      control={control}
                      rules={getValidationRules('billingName')} 
                      required={isEnabled}
                    />
                  </div>
                  <div className={styles.summaryHalf}>
                    <div className={styles.summaryInputGroup}>
                      <Input 
                        name="billing.billingEmail" 
                        label="Email"
                        placeholder='Email (e.g. "john.doe@example.com")'
                        type="email"
                        control={control}
                        rules={getValidationRules('billingEmail')} 
                        required={isEnabled}
                      />
                      {phoneInputElement}
                    </div>
                  </div>
                </div>

                <div className={styles.summaryRow}>
                  <Input 
                    name="billing.billingAddress1" 
                    label="Address" 
                    placeholder='Address (e.g. "123 Main St")'
                    control={control}
                    rules={getValidationRules('billingAddress1')} 
                    required={isEnabled}
                  />
                  <Input 
                    name="billing.billingCity" 
                    label="City" 
                    placeholder='City (e.g. "San Francisco")'
                    control={control}
                    rules={getValidationRules('billingCity')} 
                    required={isEnabled}
                  />
                  <Input 
                    name="billing.billingState" 
                    label="State"
                    placeholder={selectedCountry === "United States of America" ? "State (e.g. 'CA')" : "Province (e.g. 'Ontario')"}
                    control={control}
                    required={isEnabled}
                  />
                </div>

                <div className={styles.summaryRow}>
                  <Input 
                    name="billing.billingZip" 
                    label="Postal/Zip Code"
                    placeholder='Postal/Zip Code (e.g. "94103")'
                    control={control}
                    rules={getValidationRules('billingZip')} 
                    required={isEnabled}
                  />
                  <div className={styles.dropdownWrapper}>
                    <label htmlFor="billing.billingCountry" className={styles.label}>
                      Country {isEnabled && '*'}
                    </label>
                    <Dropdown
                      menu={{
                        items: countries?.map(country => country.label) || [],
                        onSelect: handleCountrySelect,
                      }}
                      size="small"
                    >
                      <div className={styles.preferenceValue}>
                        {selectedCountry || 'Select Country'}
                        <span className={styles.iconWrapper}>
                          <IoIosArrowDown />
                        </span>
                      </div>
                    </Dropdown>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={styles.inputGroup}>
                  <Input 
                    name="billing.billingName" 
                    label="Billing Name"
                    placeholder='Name (e.g. "John Doe")'
                    control={control}
                    rules={getValidationRules('billingName')} 
                    required={isEnabled}
                  />
                </div>
                <div className={styles.inputGroup}>
                  <Input 
                    name="billing.billingEmail" 
                    label="Email"
                    placeholder='Email (e.g. "john.doe@example.com")'
                    type="email"
                    control={control}
                    rules={getValidationRules('billingEmail')} 
                    required={isEnabled}
                  />
                  {phoneInputElement}
                </div>
                <Input 
                  name="billing.billingAddress1" 
                  label="Address" 
                  placeholder='Address (e.g. "123 Main St")'
                  control={control}
                  rules={getValidationRules('billingAddress1')} 
                  required={isEnabled}
                />
                <div className={styles.inputGroup}>
                  <Input 
                    name="billing.billingCity" 
                    label="City" 
                    placeholder='City (e.g. "San Francisco")'
                    control={control}
                    rules={getValidationRules('billingCity')} 
                    required={isEnabled}
                  />
                  <Input 
                    name="billing.billingState" 
                    label="State"
                    placeholder={selectedCountry === "United States of America" ? "State (e.g. 'CA')" : "Province (e.g. 'Ontario')"}
                    control={control}
                    required={isEnabled}
                  />
                </div>
                <div className={styles.inputGroup}>
                  <Input 
                    name="billing.billingZip" 
                    label="Postal/Zip Code"
                    placeholder='Postal/Zip Code (e.g. "94103")'
                    control={control}
                    rules={getValidationRules('billingZip')} 
                    required={isEnabled}
                  />
                  <div className={styles.dropdownWrapper}>
                    <label htmlFor="billing.billingCountry" className={styles.label}>
                      Country {isEnabled && '*'}
                    </label>
                    <Dropdown
                      menu={{
                        items: countries?.map(country => country.label) || [],
                        onSelect: handleCountrySelect,
                      }}
                      size="small"
                    >
                      <div className={styles.preferenceValue}>
                        {selectedCountry || 'Select Country'}
                        <span className={styles.iconWrapper}>
                          <IoIosArrowDown />
                        </span>
                      </div>
                    </Dropdown>
                  </div>
                </div>
              </>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default BillingDetails;
