import React, { useState, useEffect, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import Input from '../../../../../components/inputs/Input/Input';
import { Dropdown } from '../../../../../components/dropdown/Dropdown';
import { IoIosArrowDown } from 'react-icons/io';
import useStaffAdmins from '../../../../../hooks/useStaffAdmins';
import { Tooltip } from '../../../../../components/tooltip/Tooltip';
import styles from './AdditionalDetails.module.scss';
import { LoginContext } from '../../../../../contexts/LoginContext/LoginContext';

interface Props {
  showError?: boolean;
  setShowStaffIdError?: (show: boolean) => void;
  variant?: 'default' | 'summary';
}

const AdditionalDetails: React.FC<Props> = ({ 
  showError, 
  setShowStaffIdError,
  variant = 'default'
}) => {
  const { state: { staff } } = useContext(LoginContext);
  const { control, setValue, watch } = useFormContext();
  const [selectedStaff, setSelectedStaff] = useState<string | null>(null);
  const watchStaffId = watch('stfid');
  const { staffAdmins, error } = useStaffAdmins();

  useEffect(() => {
    if (staffAdmins && staff && !watchStaffId) {
      // Set default to current staff if no staff is selected
      const currentStaff = staffAdmins.find(s => s.stfid === staff.stfid);
      if (currentStaff) {
        const staffLabel = `${currentStaff.salutation} ${currentStaff.name} ${currentStaff.surname}`;
        setSelectedStaff(staffLabel);
        setValue('stfid', currentStaff.stfid);
      }
    } else if (watchStaffId && staffAdmins) {
      // Existing logic for when staff is already selected
      const matchingStaff = staffAdmins.find(s => s.stfid === watchStaffId);
      if (matchingStaff) {
        setSelectedStaff(`${matchingStaff.salutation} ${matchingStaff.name} ${matchingStaff.surname}`);
      }
    }
  }, [watchStaffId, staffAdmins, staff, setValue]);

  const handleStaffSelect = (label: string) => {
    setSelectedStaff(label);
    const selectedStaffData = staffAdmins?.find(
      s => {
        const salutation = s.salutation ?? '';
        return `${salutation} ${s.name} ${s.surname}` === label
      }
    );
    if (selectedStaffData) {
      setValue('stfid', selectedStaffData.stfid);
      if (setShowStaffIdError) {
        setShowStaffIdError(false);
      }
    }
  };

  const staffLabels = staffAdmins?.map(
    staff => {
        const salutation = staff.salutation ?? '';
        return `${salutation} ${staff.name} ${staff.surname}`;
    }
  ) || [];

  const containerClass = variant === 'summary' ? styles.summaryContainer : styles.container;
  const contentClass = variant === 'summary' ? styles.summaryContent : styles.content;

  return (
    <div className={containerClass}>
      {variant === 'default' && <h2>Additional Details</h2>}
      <div className={contentClass}>
        <div className={styles.dropdownWrapper}>
          <label htmlFor="stfid" className={styles.label}>Link FoodMarble rep</label>
          <Dropdown
            menu={{
              items: staffLabels.filter(s => s !== selectedStaff),
              onSelect: handleStaffSelect,
            }}
            size="small"
          >
            <div className={`${styles.preferenceValue} ${!selectedStaff ? styles.empty : ''} ${showError ? styles.error : ''}`}>
              {selectedStaff || 'Select Staff ID'}
              <span className={styles.iconWrapper}>
                <IoIosArrowDown />
              </span>
            </div>
          </Dropdown>
          {showError && !selectedStaff && (
            <span className={styles.errorMessage}>
              Staff ID is required
            </span>
          )}
        </div>
        <Input
          name="provider.domain"
          label="Domain"
          placeholder="Site Domain (e.g. GIAllianceTX.com)"
          control={control}
          tooltip="To build an anonymized email address for clinical trial participants"
          tooltipPosition={variant === 'summary' ? 'top' : 'right'}
        />
        <Input
          name="provider.videoLink"
          label="Video Link"
          placeholder="https://"
          control={control}
          tooltip="A video tutorial to assist with patient registration or challenges"
          tooltipPosition={variant === 'summary' ? 'top' : 'right'}
        />
      </div>
    </div>
  );
};

export default AdditionalDetails;
